import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";
import { useEffect, useState } from "react";

import './style.css';

import NavigationBar from "./Component/NavigationBar";
import Footer from "./Component/Footer";

import { ROOT, CONTACT, PRODUCT, SERVICE } from "./const";

import Home from "./Page/Home";
import Product from "./Page/Product";
import Service from "./Page/Service";
import Contact from "./Page/Contact";

const Main = () => {
	let [showElement, setShowElement] = useState(true);
	let [height, setHeight] = useState(100);
	let [navBarType, setNavBarType] = useState('default');
	let [language, setLanguage] = useState('en');
	let [screenType, setScreenType] = useState('desktop');
	let [showMenu, setShowMenu] = useState(false);
	let [position, setPosition] = useState('relative');
	let [route, setRoute] = useState(ROOT);

	useEffect(() => {
		const currRoute = window.location.pathname;
		const width = window.innerWidth;

		const initialize = () => {
			initializeLanguage();
			initializeScreenType(width);
		}

		const initializeLanguage = () => {
			const savedLanguage = localStorage.getItem('language');
			if (savedLanguage) {
				setLanguage(savedLanguage);
			}
			if (currRoute === ROOT) {
				setNavBarType('default');
				setComponents(Home);
				setRoute(ROOT);
			} else {
				setNavBarType('color');
				if (currRoute === PRODUCT) {
					setComponents(Product);
					setRoute(PRODUCT);
				} else if (currRoute === SERVICE) {
					setComponents(Service);
					setRoute(SERVICE);
				} else if (currRoute === CONTACT) {
					setHeight(78);
					setComponents(Contact);
					setRoute(CONTACT);
				} else {
					setShowElement(false);
				}
			}
		}

		const initializeScreenType = (width) => {
			if (width <= 1100) {
				setScreenType('mobile')
			}
		}
		initialize();
	}, []);

	useEffect(() => {
		const handleScroll = (e) => {
			const {scrollTop, scrollHeight} = e.target;
			const isStart = scrollTop === 0;
			const newPosition = isStart ? 'relative' : 'fixed';
			setPosition(newPosition);
		};

		window.addEventListener('scroll', handleScroll, true);
		return()=>window.removeEventListener('scroll', handleScroll, true);

	}, [position]);

	const updateLanguage = (value) => {
		setLanguage(value);
		localStorage.setItem('language', value);
	}

	let [Components, setComponents] = useState({
		FirstComponent: () => <div></div>,
		Components: () => <div></div>
	});

	const index = createBrowserRouter([
		{
			path: ROOT,
			element: Components.Components(language)
		},
		{
			path: PRODUCT,
			element: Components.Components(language)
		},
		{
			path: SERVICE,
			element: Components.Components(language)
		},
		{
			path: CONTACT,
			element: Components.Components(language)
		}
	]);
	const MobileView = ()=>{
		return(
			<div>
				<NavigationBar navBarType={navBarType} setLanguage={updateLanguage}
				               language={language} screenType={screenType} showMenu={showMenu}
				               setShowMenu={setShowMenu}
				               position={position}
				/>
				<div className={'first-component'}>
					{ navBarType === 'default' ?
						<div id={'first-component-text'}>
							Wooden Pallets, Industrial Packaging,<br/>
							Fumigation Services, Warehouse & Inventory<br/>
							Management, & ISPM#15 Treatment Provider:<br/>
							ID-015, ID-049, ID-117
						</div> : <></>
					}
					<img id={'first-component-image'} src={Components.bgMobileUrl}/>
					{ navBarType === 'default' ?
						<button id={'company-info-button'} onClick={() => {
							window.location.href = '/product'
						}}>SEE MORE
						</button> : <></>
					}
				</div>
			</div>
		);
	}

	const DesktopView = ()=>{
		return (
			<div className={'first-component'}>
				<NavigationBar navBarType={navBarType} setLanguage={updateLanguage}
							   language={language}
							   screenType={screenType}
							   position={position}
							   route={route}
				/>
				<img className={'fc-desktop-image'} src={Components.bgImageUrl} alt={'bg'}/>
				{
					navBarType === 'default' ?
						<div className={'fc-desktop-container'}>
							{Components.FirstComponent(language)}
						</div> : <></>
				}
			</div>
		);
	}

	const Body = () => {
		return (
			<div className={'page-container'}>
				<RouterProvider router={index}/>
			</div>
		);
	}

	return (
		<div className={`page ${showMenu ? 'lock-scroll' : ''}`}>
			{
				screenType === 'mobile' ?
					<MobileView/> : <DesktopView/>
			}
			<Body/>
			<Footer/>
		</div>
	);
}

export default Main;